@charset "utf-8";


@import "../../node_modules/bulma/bulma.sass";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');


.title, .subtitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.navbar {
    font-family: 'Montserrat', sans-serif;
}

body {
    // font-family: 'Merrweather', serif;
    font-size: 125%;
}

.has-text-weight-800 {
    font-weight: 800;
}

.navbar-item.active {
    font-weight: 700;
}

.has-tight-spacing {
    letter-spacing: -1px;
}

.solution-tile {
    background-color: white;
}

.fidelity-tile {
    background-color: white;
    border: 1px solid #333;
}

.content ul {
    margin-left: 0.5rem;
}

.navbar-item img {
    max-height: 4rem;
}

.hifi-img {
    min-width: 65px;
}

.hero-img {
    object-fit: contain;
    width: 100%;
    max-height: 375px;
}

.solution-img {
    object-fit: contain;
    width: 100%;
    max-height: 256px;
}

.about-img {
    vertical-align: middle;
}

.with-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

}